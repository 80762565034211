import { APICore } from "./apiCore";

const api = new APICore();

export function updateProfile(params: any) {
  const endpoint = "/accounts/user/profile/";
  return api.updatePatch(`${endpoint}`, params);
}

export function updatePassword(params: any) {
  const endpoint = "/accounts/user/password-change/";
  return api.create(`${endpoint}`, params);
}

export function getUsers() {
  const endpoint = "/accounts/admin/users/";
  return api.get(`${endpoint}`, {});
}
export function getStaffUsers() {
  const endpoint = "/accounts/admin/staffs/";
  return api.get(`${endpoint}`, {});
}

export function createUser(params: any) {
  const endpoint = "/accounts/admin/staffs/";
  return api.create(`${endpoint}`, params);
}

export function updateUser(params: any) {
  const endpoint = `/accounts/admin/staffs/${params.id}/`;
  return api.updatePatch(`${endpoint}`, {
    ...params,
    user_status: params.status,
  });
}

export function updateCustomer(params: any) {
  const endpoint = `/accounts/admin/users/${params.id}/`;
  return api.updatePatch(`${endpoint}`, {
    ...params,
    user_status: params.status,
  });
}
