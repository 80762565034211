import { APICore } from "./apiCore";

const api = new APICore();

export function getLocations() {
  const endpoint = "/properties/admin/locations/";
  return api.get(`${endpoint}`, {});
}

export function getAllLocations(data: any) {
  const endpoint = "/properties/admin/locations/";
  return api.get(`${endpoint}`, data);
}

export function getLocationDetails(params: any) {
  const endpoint = `/properties/admin/locations/${params.id}/`;
  return api.get(`${endpoint}`, {});
}
export function getLocationDetailsPublic(params: any) {
  const endpoint = `/properties/public/locations/${params.id}/`;
  return api.get(`${endpoint}`, {});
}
